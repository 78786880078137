import React, { useState } from "react";
import { Link } from "react-router-dom";
import Product from "../Product/Product";
import { motion } from "framer-motion";
import { useWindowSize } from "@/hooks/windowSize";
import { useLanguages } from "@/context/LanguageContext";
interface Props {
  data: any;
}

const TabFeatures: React.FC<Props> = ({ data }) => {
  const [activeTab, setActiveTab] = useState<string>("best sellers");
  const { t } = useLanguages();
  const handleTabClick = (item: string) => {
    setActiveTab(item);
  };
  const { size } = useWindowSize();

  const getFilterData = () => {
    if (activeTab === "on sale") {
      return data?.sales || [];
    }

    if (activeTab === "new arrivals") {
      return data?.newArrivals || [];
    }

    if (activeTab === "best sellers") {
      return data?.bestSellers || [];
    }

    return data;
  };

  const filteredProducts = getFilterData();

  return (
    <>
      <div className="tab-features-block md:pt-20 pt-10">
        <div className="container">
          <div className="heading flex flex-col items-center text-center">
            <div className="menu-tab flex items-center gap-2 p-1 bg-surface rounded-2xl">
              {["best sellers", "on sale", "new arrivals"].map(
                (item, index) => (
                  <div
                    key={index}
                    className={`tab-item relative text-secondary heading5 py-2 px-5 cursor-pointer duration-500 hover:text-black ${
                      activeTab === item ? "active" : ""
                    }`}
                    onClick={() => handleTabClick(item)}
                  >
                    {activeTab === item && (
                      <motion.div
                        layoutId="active-pill"
                        className="absolute inset-0 rounded-2xl bg-white"
                      ></motion.div>
                    )}
                    <span className="relative heading5 z-[1]">{t(item)}</span>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="list-product grid lg:grid-cols-4 grid-cols-2 gap-[30px] hide-product-sold section-swiper-navigation  md:mt-10 mt-6">
            {filteredProducts
              .slice(0, size === "lg" ? 4 : 2)
              // @ts-ignore
              .map((prd, index) => (
                <Product key={index} data={prd} type="grid" />
              ))}
          </div>
          <div className="block-button flex items-center justify-center w-full md:mt-10 mt-6">
            {/* @ts-ignore */}
            <Link to={"/shop"} className="button-main text-center">
              {t("View All Products")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabFeatures;
